import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/api/api'
import ApiNoBearer from '@/api/apiNoBearer'
import router from '@/router'
import {
  createStore
} from 'vuex-extensions'
import gradient from "gradient-color";
import _ from 'lodash'
import jwt_decode from "jwt-decode";

Vue.use(Vuex)

const store = createStore(Vuex.Store, {
  state: {
    /***** SETTINGS *****/
    token: localStorage.getItem('token') || null,
    jwt: null,
    userId: null,
    stripeKey: null,
    skipCheckout: false,
    stripe: null,
    retarget: false,
    clientSecret: null,
    loading: false,
    dataLoading: false,
    publicCustomization: null,
    /***** USER DATA *****/
    userData: {
      firstName: null,
      lastName: null,
      email: null,
      referralCoupon: null,
      customization: null,
    },
    senderEmail: null,
    oldPassword: '',
    password: null,
    utm_campaign: null,
    utm_medium: null,
    utm_content: null,
    utm_source: null,
    stopSearchComment: '',
    /***** SUBSCRIPTION *****/
    dbSubscriptionObject: {},
    stripeUserObject: {},
    bills: null,
    apiKeys: [],
    apiKeysUsage: [],
    /***** AD *****/
    currentAd: null,
    currentDisplayAd: null,
    countAds: 0,
    ads: [],
    nextPage: null,
    lastPage: null,
    currentPage: null,
    adsInCart: [],
    /***** FAVORITES *****/
    countFavorites: 0,
    favoriteNextPage: null,
    favoriteLastPage: null,
    favoriteCurrentPage: null,
    /***** COMPONENTS STATE *****/
    isPhoneValid: false,
    isFavoritesView: false,
    currentView: 'search-results',
    isComponentLoaded: false,
    haveFiltersBeenUpdated: false,
    isUserLoaded: false,
    hasInputBeenChanged: false,
    isStripeElementOpened: false,
    isSearchNameEditable: false,
    isSearchLoaded: false,
    hasFilterPanelInputBeenChanged: false,
    isSearchInputOpened: false,
    isFilterOpen: false,
    isNavOpen: false,
    hasCustomizationBeenUpdated: false,
    /***** SEARCH *****/
    searches: [],
    currentSearch: {},
    favorites: [],
    isSearchSavable: false,
    expired: false,
    sortBy: 'order[updatedAt]=desc',
    fromType: null,
    fromTo: '',
    defaultSearch: {
      title: 'Ma 1e recherche',
      transactionType: 0,
      budgetMin: null,
      budgetMax: 975000,
      pricePerMeterMin: null,
      pricePerMeterMax: null,
      hidePropertyContact: false,
      surfaceMin: null,
      surfaceMax: null,
      landSurfaceMin: null,
      landSurfaceMax: null,
      propertyTypes: [0, 1],
      publisherTypes: [0, 1],
      bedroomMin: 1,
      notificationEnabled: false,
      notificationRecipient: null,
      includedCities: [],
      includedDepartments: [{
        '@id': '/departments/77',
        '@type': 'Department',
        chefLieu: '75056',
        departmentCode: '75',
        name: 'Paris',
        nameClean: 'PARIS',
      },],
      excludedCities: [],
      expressions: [],
    },
    /***** DATA *****/
    data: {
      transactionType: 0,
      city: null,
      dvf: null,
      dvfRecent: null,
      avgPricesPerMeterPRO: null,
      avgPricesPerMeterIND: null,
      medianPricesPerMeterPRO: null,
      medianPricesPerMeterIND: null,
      adsPriceDistribution: null,
      adsPriceDistributionCount: null,
      adsSurfaceDistribution: null,
      adsSurfaceDistributionCount: null,
      searchesPriceDistribution: null,
      searchesPriceDistributionCount: null,
      searchesSurfaceDistribution: null,
      searchesSurfaceDistributionCount: null,
      adsCategory: null,
      adsPublisherType: null,
      avgPricePerMeter: null,
      adsCategoryBuy: null,
      adsCategoryRent: null,
      opportunitiesPreview: null,
    },
  },
  mutations: {
    /***** SETTINGS *****/
    SET_STRIPE(state, value) {
      state.stripe = value
    },
    SET_SKIP_CHECKOUT(state, value) {
      state.skipCheckout = value
    },
    SET_UTM_CAMPAIGN(state, value) {
      state.utm_campaign = value
    },
    SET_UTM_CONTENT(state, value) {
      state.utm_content = value
    },
    SET_UTM_SOURCE(state, value) {
      state.utm_source = value
    },
    SET_UTM_MEDIUM(state, value) {
      state.utm_medium = value
    },
    SET_RETARGET(state, value) {
      state.retarget = value
    },
    RETRIEVE_TOKEN(state, token) {
      state.token = token
    },
    SET_USER_ID(state, value) {
      state.userId = value
    },
    SET_STRIPE_KEY(state, value) {
      state.stripeKey = value
    },
    SET_CLIENT_SECRET(state, value) {
      state.clientSecret = value
    },
    UPDATE_LOADING(state, value) {
      state.loading = value
    },
    UPDATE_DATA_LOADING(state, value) {
      state.dataLoading = value
    },
    SET_PUBLIC_CUSTOMIZATION(state, value) {
      state.publicCustomization = value
    },
    /***** USER DATA *****/
    SET_EMAIL(state, value) {
      state.userData.email = value
    },
    SET_PASSWORD(state, value) {
      state.password = value
    },
    SET_OLD_PASSWORD(state, value) {
      state.oldPassword = value
    },
    SET_STOP_SEARCH_COMMENT(state, value) {
      state.stopSearchComment = value
    },
    SET_SENDER_EMAIL_ADDRESS(state, value) {
      state.userData.customization.senderEmailAddress = value
    },
    SET_SENDER_EMAIL_ADDRESS_VERIFIED(state, value) {
      state.userData.customization.senderEmailVerified = value
    },
    SET_SENDER_EMAIL_NAME(state, value) {
      state.userData.customization.senderEmailName = value
    },
    SET_PRIMARY_COLOR(state, value) {
      state.userData.customization.primaryColor = value
    },
    SET_LOGO(state, value) {
      state.userData.customization.logo = value
    },
    SET_CUSTOMIZATION(state, value) {
      state.userData.customization.enabled = value
    },
    SET_DISPLAY_CONTACT_EMAIL(state, value) {
      state.userData.customization.displayContactEmail = value
    },
    SET_DISPLAY_CONTACT_NAME(state, value) {
      state.userData.customization.displayContactName = value
    },
    SET_DISPLAY_CONTACT_PHONE(state, value) {
      state.userData.customization.displayContactPhone = value
    },
    SET_DEFAULT_CUSTOMIZATION(state, value) {
      state.userData.customization = value
    },
    /***** SUBSCRIPTION *****/
    SET_STRIPE_SUBSCRIPTION_OBJECT(state, object) {
      state.dbSubscriptionObject = object
    },
    SET_STRIPE_USER_OBJECT(state, object) {
      state.stripeUserObject = object
    },
    SET_BILLS(state, array) {
      state.bills = array
    },
    /***** ADS *****/
    SET_ADS(state, ads) {
      state.ads = ads
    },
    SET_DISPLAY_AD(state, ad) {
      state.currentDisplayAd = ad
    },
    SET_ADS_NEXT_PAGE(state, ads) {
      state.ads = [...state.ads, ...ads]
    },
    ADD_AD_TO_CART(state, adIRI) {
      state.adsInCart.push(adIRI)
    },
    REMOVE_AD_FROM_CART(state, adId) {
      let index = state.adsInCart.indexOf(adId)
      state.adsInCart.splice(index, 1)
    },
    SET_FAVORITES_NEXT_PAGE(state, ads) {
      state.favorites = [...state.favorites, ...ads]
    },
    SET_FAVORITE(state, {
      payload,
      favoriteIRI
    }) {
      if (!state.isFavoritesView) {
        let index = state.ads.findIndex(ad => ad.property == payload.adIRI);
        Vue.set(state.ads[index], 'favorite', {})
        state.ads[index].favorite["@id"] = favoriteIRI
        state.ads[index].favorite.comments = payload.comment
      }
      if (state.isFavoritesView) {
        let favoriteIndex = state.favorites.findIndex(favorite => favorite["@id"] ==
          favoriteIRI);
        if (state.favorites.length) state.favorites[favoriteIndex].comments = payload.comment
      }
    },
    REMOVE_FAVORITE(state, favoriteIRI) {
      /*--Remove Favorite from Favorite list--*/
      state.favorites = state.favorites.filter(item => item["@id"] !== favoriteIRI)
      /*--Remove Favorite from Ad list--*/
      let ads = state.ads.filter(ad => ad.favorite)
      let currentAd = ads.find(ad => ad.favorite["@id"] == favoriteIRI);
      if (currentAd) {
        let index = state.ads.findIndex(ad => ad.property == currentAd.property);
        Vue.delete(state.ads[index], 'favorite')
      }
    },
    SET_EMPTY_CART(state) {
      state.adsInCart = []
    },
    SET_COUNT_ADS(state, value) {
      state.countAds = value
    },
    SET_NEXT_PAGE(state, value) {
      state.nextPage = value
    },
    SET_CURRENT_PAGE(state, value) {
      state.currentPage = value
    },
    SET_LAST_PAGE(state, value) {
      state.lastPage = value
    },
    SET_CURRENT_AD(state, payload) {
      state.currentAd = payload.ad
      if (state.isFavoritesView) state.currentAd.favorite = payload.favorite
    },
    /***** COMPONENTS STATE *****/
    SET_CURRENT_VIEW(state, value) {
      state.currentView = value
    },
    SET_HAVE_FILTERS_BEEN_UPDATED(state, value) {
      state.haveFiltersBeenUpdated = value
    },
    SET_HAS_CUSTOMIZATION_BEEN_UPDATED(state, value) {
      state.hasCustomizationBeenUpdated = value
    },
    SET_HAS_INPUT_BEEN_CHANGED(state, value) {
      state.hasInputBeenChanged = value
    },
    SET_HAS_FILTER_PANEL_INPUT_BEEN_CHANGED(state, value) {
      state.hasFilterPanelInputBeenChanged = value
    },
    SET_IS_COMPONENT_LOADED(state, value) {
      state.isComponentLoaded = value
    },
    TOGGLE_FILTER(state) {
      if (state.isFilterOpen === true) {
        router.replace({
          path: router.currentRoute.path
        })
      }
      state.isFilterOpen = !state.isFilterOpen
    },
    TOGGLE_NAV(state) {
      state.isNavOpen = !state.isNavOpen
    },
    CLOSE_NAV(state) {
      state.isNavOpen = false
    },
    SET_IS_USER_LOADED(state, value) {
      state.isUserLoaded = value
    },
    SET_USER_DATA(state, value) {
      state.userData = value
    },
    SET_IS_PHONE_VALID(state, value) {
      state.isPhoneValid = value
    },
    /***** SEARCH *****/
    SET_SEARCHES(state, searches) {
      state.searches = searches
    },
    SET_CURRENT_SEARCH(state, selectedSearch) {
      state.currentSearch = selectedSearch
    },
    SET_SORT_BY(state, value) {
      state.sortBy = value
    },
    SET_FROM_TO(state, value) {
      state.fromTo = value
    },
    CREATE_NEW_SEARCH(state, newSearchName) {
      state.defaultSearch.title = newSearchName
      state.currentSearch = state.defaultSearch
      state.isSearchSavable = false
    },
    SET_SEARCH_NAME(state, value) {
      state.currentSearch.title = value
    },
    SET_NOTIFICATION_EMAIL(state, value) {
      state.currentSearch.notificationRecipient = value
    },
    SET_NOTIFICATION_BY_EMAIL(state, value) {
      state.currentSearch.notificationEnabled = value
    },
    SET_TRANSACTION_TYPE(state, value) {
      state.currentSearch.transactionType = value
      state.haveFiltersBeenUpdated = true
    },
    SET_PROPERTY_TYPES(state, values) {
      state.currentSearch.propertyTypes = values
      state.haveFiltersBeenUpdated = true
    },
    SET_PUBLISHER_TYPES(state, values) {
      state.currentSearch.publisherTypes = values
      state.haveFiltersBeenUpdated = true
    },
    SET_INCLUDED_LOCATIONS(state, {
      cities,
      departments
    }) {
      state.currentSearch.includedCities = cities
      state.currentSearch.includedDepartments = departments
      state.haveFiltersBeenUpdated = true
    },
    SET_EXCLUDED_CITIES(state, excludedCities) {
      state.currentSearch.excludedCities = excludedCities
      state.haveFiltersBeenUpdated = true
    },
    SET_PRICE_MIN(state, value) {
      state.currentSearch.budgetMin = value
      state.haveFiltersBeenUpdated = true
    },
    SET_PRICE_MAX(state, value) {
      state.currentSearch.budgetMax = value
      state.haveFiltersBeenUpdated = true
    },
    SET_PRICE_PER_METER_MIN(state, value) {
      state.currentSearch.pricePerMeterMin = value
      state.haveFiltersBeenUpdated = true
    },
    SET_PRICE_PER_METER_MAX(state, value) {
      state.currentSearch.pricePerMeterMax = value
      state.haveFiltersBeenUpdated = true
    },
    SET_SURFACE_MIN(state, value) {
      state.currentSearch.surfaceMin = value
      state.haveFiltersBeenUpdated = true
    },
    SET_SURFACE_MAX(state, value) {
      state.currentSearch.surfaceMax = value
      state.haveFiltersBeenUpdated = true
    },
    SET_BEDROOMS(state, value) {
      state.currentSearch.bedroomMin = value
      state.haveFiltersBeenUpdated = true
    },
    UPDATE_EXPRESSIONS(state, values) {
      state.currentSearch.expressions = values
      state.haveFiltersBeenUpdated = true
    },
    UPDATE_SAVABLE_SEARCH(state, value) {
      state.isSearchSavable = value
    },
    SET_HIDE_PROPERTY_CONTACT(state, value) {
      state.currentSearch.hidePropertyContact = value
    },
    SET_IS_SEARCH_LOADED(state) {
      state.isSearchLoaded = true
    },
    /***** REUSABLE MUTATIONS *****/
    SET_SEARCH_FILTER(state, {
      filter,
      newValue
    }) {
      state.currentSearch[filter] = newValue
      if (filter && filter.includes('landSurface')) state.haveFiltersBeenUpdated = true
    },
    splice(state, {
      property,
      value,
      newValue
    }) {
      let index = state[property].findIndex(obj => obj["@id"] === value);
      if (index >= 0) {
        state[property].splice(index, 1, newValue);
      }
    },
    update(state, {
      property,
      newValue
    }) {
      state[property] = newValue
      if (property && property.includes('expired')) state.haveFiltersBeenUpdated = true
    },
    push(state, {
      property,
      newValue
    }) {
      state[property].push(newValue)
    },
    pushNested(state, { propertyPath, newValue }) {
      let target = state;
      for (let i = 0; i < propertyPath.length - 1; i++) {
        target = target[propertyPath[i]];
      }
      target[propertyPath[propertyPath.length - 1]].push(newValue);
    },
    updateNested(state, { propertyPath, newValue }) {
      let target = state;
      for (let i = 0; i < propertyPath.length - 1; i++) {
        target = target[propertyPath[i]];
      }
      target[propertyPath[propertyPath.length - 1]] = newValue;
    },
    unshift(state, {
      property,
      newValue
    }) {
      if (!state[property].some(item => JSON.stringify(item) === JSON.stringify(newValue)))
        state[property].unshift(newValue)
    },
    /***** DATA *****/
    SET_DATA(state, {
      target,
      value
    }) {
      state.data[target] = value
    },
    SET_DATA_TRANSACTION_TYPE(state, value) {
      state.data.transactionType = value
    },
    SET_DATA_CITY(state, value) {
      state.data.city = value
    },
  },
  actions: {
    /*--------REGISTRATION--------*/
    /*--Stripe JS--*/
    async setStripe({
      commit
    }) {
      return ApiNoBearer()
        .get('/public/settings')
        .then((res) => {
          if (res.data.STRIPE_PUB_KEY) {
            commit('SET_STRIPE_KEY', res.data.STRIPE_PUB_KEY)
            commit('SET_STRIPE', window.Stripe(res.data.STRIPE_PUB_KEY))
          }
        })
    },
    /*--Form--*/
    async registerUser({
      commit,
      state,
      dispatch
    }) {
      ApiNoBearer()
        .post('/public/registration', {
          email: state.userData.email,
          password: state.password,
          utmSource: state.utm_source,
          utmMedium: state.utm_medium,
          utmCampaign: state.utm_campaign,
          utmContent: state.utm_content,
        })
        .then((res) => {
          commit('SET_USER_ID', res.data['@id'])
        })
        .then(() => {
          dispatch('stripeRegister')
        })
        .finally(() => {
          state.password = ''
        })
    },
    /*--Stripe--*/
    async stripeRegister({
      state
    }) {
      let successUrl =
        window.location.origin + '/thanks' + '?email=' + state.userData.email
      let successUrlRetarget = window.location.origin + '/thanks-retarget'
      ApiNoBearer()
        .post('/public/payment/create-checkout-session', {
          user: state.userId,
          successUrl: state.retarget == true ? successUrlRetarget : successUrl,
          cancelUrl: window.location.origin,
          plan: 'melo_standard_multi_search_plan',
          retarget: state.retarget,
          skipCheckout: state.skipCheckout,
          allowPromotionCodes: true,
        })
        .then((res) => {
          if (
            !res.data.stripeSessionCheckoutID &&
            state.skipCheckout === true
          ) {
            router.push({
              path: '/thanks' +
                '?email=' +
                state.userData.email +
                '&customerID=' +
                res.data.customerID,
            })
          } else {
            state.stripe.redirectToCheckout({
              sessionId: res.data.stripeSessionCheckoutID,
            })
          }
        })
    },
    /*--Confirm User with Token in Email Link--*/
    async confirmAccount({
      commit
    }, confirmationToken) {
      ApiNoBearer()
        .post('/public/security/confirmation', {
          confirmationToken: confirmationToken,
        })
        .then((res) => {
          const token = res.data.token
          localStorage.setItem('token', token)
          commit('RETRIEVE_TOKEN', token)
          router.push({
            path: '/search'
          })
        })
    },

    /*--Login--*/
    async retrieveToken({
      commit,
      state
    }) {
      ApiNoBearer()
        .post('/auth', {
          email: state.userData.email,
          password: state.password,
        })
        .then((res) => {
          const token = res.data.token
          localStorage.setItem('token', token)
          commit('RETRIEVE_TOKEN', token)
        })
        .then(() => {
          router.push({
            name: 'search'
          })
          state.password = ''
        })
    },
    decodeToken({
      commit, state
    }) {
      try {
        const decoded = jwt_decode(state.token);
        commit('update', {
          property: 'jwt',
          newValue: decoded
        })
      } catch (error) {
        console.error("Invalid token", error);
        // Handle the error here
      }
    },
    /*--SUBSCRIPTIONS--*/
    /*--Fetch User Data--*/
    async fetchUserData({
      commit
    }) {
      return Api()
        .get('/account/user')
        .then((res) => {
          commit('SET_USER_DATA', res.data)
          commit('SET_USER_ID', res.data['@id'])
        })
        .then(() => {
          commit('SET_IS_USER_LOADED', true)
        })
    },
    /*--Fetch User dB Subscription--*/
    async fetchSubscription({
      commit
    }) {
      return Api()
        .get('/account/subscription')
        .then((res) => {
          commit('SET_STRIPE_SUBSCRIPTION_OBJECT', res.data.subscription)
        })
    },
    /*--Fetch User dB + Stripe Subscription--*/
    async fetchStripeAndSubscription({
      commit,
      state
    }) {
      return Api()
        .get('/account/subscription?withStripe=true')
        .then((res) => {
          if (res.data.stripe) {
            commit('SET_STRIPE_USER_OBJECT', res.data.stripe)
          }
          if (res.data.subscription) {
            commit('SET_STRIPE_SUBSCRIPTION_OBJECT', res.data.subscription)
          }
        })
        .then(() => {
          state.isStripeElementOpened = false
        })
    },
    /*--Switch Subscription to Other Plan or Unsubscribed Plan--*/
    async switchSubscription({ dispatch, state, commit }, value) {
      try {
        const res = await Api().post('/account/switch-subscription', {
          toPlan: value,
          comment: state.stopSearchComment,
        });

        if (res.data.success) {
          commit('UPDATE_LOADING', true)

          // Introduce delay
          await new Promise(resolve => setTimeout(resolve, 3500));

          await dispatch('fetchStripeAndSubscription');
          await dispatch('fetchUserData');
          Vue.notify({
            group: 'global',
            title: 'Votre abonnement a été mis à jour',
          });
        }
        return res;
      } catch (error) {
        console.log(error)
      }
    },
    /*--Create Sripe Setup Intent--*/
    async createSetupIntent({
      commit
    }) {
      ApiNoBearer()
        .get('/public/payment/create-setup-intent')
        .then((res) => {
          commit('SET_CLIENT_SECRET', res.data.client_secret)
        })
    },
    /*--Confirm Stripe Setup Intent--*/
    async confirmSetupIntent({
      dispatch
    }, setupIntent) {
      Api()
        .post('/payment/confirm-setup-intent', {
          setupIntent: setupIntent,
        })
        .then(() => {
          setTimeout(() => {
            dispatch('fetchStripeAndSubscription')
          }, 2000)
        })
        .then(() => {
          Vue.notify({
            group: 'global',
            title: 'Votre moyen de paiement a bien été mis à jour',
          })
        })
    },
    /*--------SEARCHES--------*/
    /*--Fetch Searches--*/
    async fetchSearches({
      commit,
      getters,
      state
    }) {
      return Api()
        .get('/searches?initiatedFromDashboard=true')
        .then((res) => {
          if (res.data['hydra:member'].length > 0) {
            commit('SET_SEARCHES', res.data['hydra:member'])
            commit('SET_CURRENT_SEARCH', getters.getLastUpdatedSearch)
          } else {
            commit('CREATE_NEW_SEARCH', state.defaultSearch.title)
          }
        })
        .then(() => {
          commit('SET_IS_SEARCH_LOADED', true)
        })
    },
    /*--Save or Create Search if it doesn't exit--*/
    saveSearch({
      dispatch,
      getters
    }) {
      if (!getters.doesSearchHaveUUID) {
        dispatch('createAndSaveCurrentSearch')
      } else {
        dispatch('saveCurrentSearch')
      }
    },
    /*--Save Existing Search--*/
    async saveCurrentSearch({
      commit,
      getters,
      dispatch
    }) {
      Api()
        .put(getters.getCurrentSearchId, getters.currentSearchCleansedForPUT, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          if (!res.data['/contexts/Error']) {
            commit('SET_CURRENT_SEARCH', res.data)
            Vue.notify({
              group: 'global',
              title: 'Votre recherche a bien été sauvegardée',
            })
          }
        })
        .then(() => {
          dispatch('fetchSearches').then(() => {
            dispatch('fetchAds')
          })

          commit('UPDATE_SAVABLE_SEARCH', false)
        })
    },
    /*--Create New Search and Save It--*/
    async createAndSaveCurrentSearch({
      commit,
      getters,
      dispatch
    }) {
      Api()
        .post('/searches', getters.currentSearchCleansedForPUT)
        .then((res) => {
          if (!res.data['/contexts/Error']) {
            commit('SET_CURRENT_SEARCH', res.data)
            Vue.notify({
              group: 'global',
              title: 'Une nouvelle recherche a bien été créée 🔥<br><br>Vous pouvez commencer à paramétrer les filtres',
            })
          }
        })
        .then(() => {
          dispatch('fetchSearches').then(() => {
            dispatch('fetchAds')
            commit('UPDATE_SAVABLE_SEARCH', false)
          })
        })
    },
    /*--Set Search Name--*/
    async setSearchName({
      commit
    }, newSearchName) {
      commit('SET_SEARCH_NAME', newSearchName)
    },
    /*--Create New Search--*/
    async createNewSearch({
      commit
    }, newSearchName) {
      commit('CREATE_NEW_SEARCH', newSearchName)
    },
    /*--Remove Search--*/
    async removeSearchFromDB({
      getters,
      dispatch
    }) {
      Api()
        .delete(getters.getCurrentSearchId)
        .then(() => {
          Vue.notify({
            group: 'global',
            title: 'Votre recherche a bien été supprimée',
          })
          dispatch('fetchSearches')
        })
    },
    /*--Select Search in Multiselect--*/
    async setSelectedSearch({
      commit
    }, selectedSearch) {
      new Promise((resolve, reject) => {
        commit('SET_CURRENT_SEARCH', selectedSearch)
        commit('UPDATE_SAVABLE_SEARCH', false)
        commit('SET_HAS_FILTER_PANEL_INPUT_BEEN_CHANGED', false)
        commit('SET_HAS_INPUT_BEEN_CHANGED', false)
        resolve();
      })

    },
    /*--------ADS--------*/
    /*--Sorting--*/
    async sortAds({
      getters,
      dispatch
    }) {
      await getters.getCurrentSortBy
      await dispatch('fetchAds')
    },
    async fromToAds({
      getters,
      dispatch
    }) {
      await getters.getCurrentFromTo
      await dispatch('fetchAds')
    },
    /*--Fetch Ads--*/
    async fetchAds({
      commit,
      getters,
      state
    }) {
      commit('SET_NEXT_PAGE', null)
      return Api()
        .get('/dashboard/properties?' + state.sortBy + state.fromType + state.fromTo +
          '&itemsPerPage=12', {
          params: getters.removeUnrequiredParamsFromFetchAds,
        })
        .then((res) => {
          if (res.data['hydra:member'])
            commit('SET_ADS', res.data['hydra:member'])
          if (_.has(res, "data['hydra:totalItems']"))
            commit('SET_COUNT_ADS', res.data['hydra:totalItems'])
          if (res.data['hydra:view']['@id'])
            commit('SET_CURRENT_PAGE', res.data['hydra:view']['@id'])
          if (res.data['hydra:view']['hydra:next'])
            commit('SET_NEXT_PAGE', res.data['hydra:view']['hydra:next'])
          if (res.data['hydra:view']['hydra:last'])
            commit('SET_LAST_PAGE', res.data['hydra:view']['hydra:last'])
        })
        .then(() => {
          commit('SET_HAVE_FILTERS_BEEN_UPDATED', false)
          commit('SET_HAS_INPUT_BEEN_CHANGED', false)
        })
    },
    /*--Fetch Ads Next Page--*/
    async fetchAdsNextPage({
      commit,
      getters,
      state
    }) {
      Api()
        .get(
          '/dashboard/properties?' +
          state.sortBy +
          state.fromType +
          state.fromTo + '&itemsPerPage=12' +
          '&page=' +
          getters.getNextPage, {
          params: getters.removeUnrequiredParamsFromFetchAds,
        }
        )
        .then((res) => {
          if (res.data['hydra:member'])
            commit('SET_ADS_NEXT_PAGE', res.data['hydra:member'])
          if (res.data['hydra:view']['hydra:next'])
            commit('SET_NEXT_PAGE', res.data['hydra:view']['hydra:next'])

          if (res.data['hydra:view']['@id'])
            commit('SET_CURRENT_PAGE', res.data['hydra:view']['@id'])
        })
    },
    /*--Fetch Ad to Display to User from his Match-- NO BEARER NEEDED*/
    async fetchAdDisplay({
      commit
    }, payload) {
      ApiNoBearer()
        .get(
          '/public/match?token=' +
          payload.token +
          '&customization=' +
          payload.customization,
        )
        .then((res) => {
          if (res.data.uuid) commit('SET_DISPLAY_AD', res.data)
          //else router.push({ name: 'expired' })
        })
    },
    async fetchPublicCustomization({
      commit
    }, uuid) {
      return ApiNoBearer()
        .get('/public/customizations' + uuid)
        .then((res) => {
          if (res.data) commit('SET_PUBLIC_CUSTOMIZATION', res.data)
        })
    },
    async sendCart({
      state,
      getters,
      commit
    }, payload) {
      return Api()
        .post('/ad-cart', {
          search: getters.getCurrentSearchId,
          properties: state.adsInCart,
          emailRecipient: payload.recipient,
          hidePropertyContact: state.currentSearch.hidePropertyContact,
          subject: payload.subject,
        })
        .then(() => {
          commit('SET_EMPTY_CART')
          Vue.notify({
            group: 'global',
            title: 'Votre email a bien été envoyé',
          })
        })
    },
    async updateCart({
      state,
      commit
    }, adIRI) {
      state.adsInCart.includes(adIRI) ?
        commit('REMOVE_AD_FROM_CART', adIRI) :
        commit('ADD_AD_TO_CART', adIRI)
    },
    /*--FETCH Favorites--*/
    async fetchFavorites({
      state,
      commit
    }) {
      return Api().get(state.currentSearch["@id"] + '/favorites?withPropertyDocument=true')
        .then(
          res => {
            if (res.data['hydra:member'])
              commit('update', {
                property: 'favorites',
                newValue: res.data['hydra:member']
              })
            if (_.has(res, "data['hydra:totalItems']"))
              commit('update', {
                property: 'countFavorites',
                newValue: res.data['hydra:totalItems']
              })
            if (res.data['hydra:view']['@id'])
              commit('update', {
                property: 'favoriteCurrentPage',
                newValue: res.data['hydra:view']['@id']
              })
            if (res.data['hydra:view']['hydra:next'])
              commit('update', {
                property: 'favoriteNextPage',
                newValue: res.data['hydra:view']['hydra:next']
              })
            if (res.data['hydra:view']['hydra:last'])
              commit('update', {
                property: 'favoriteLastPage',
                newValue: res.data['hydra:view']['hydra:last']
              })
          })
    },
    /*--FETCH Favorites Next Page--*/
    async fetchFavoritesNextPage({
      commit,
      getters,
      state
    }) {
      Api()
        .get(
          '/favorites?' +
          state.sortBy +
          state.fromType +
          state.fromTo +
          '&page=' +
          getters.getFavoriteNextPage
        )
        .then((res) => {
          if (res.data['hydra:member'])
            commit('SET_FAVORITES_NEXT_PAGE', res.data['hydra:member'])
          if (res.data['hydra:view']['hydra:next'])
            commit('SET_FAVORITE_NEXT_PAGE', res.data['hydra:view']['hydra:next'])

          if (res.data['hydra:view']['@id'])
            commit('SET_FAVORITE_CURRENT_PAGE', res.data['hydra:view']['@id'])
        })
    },
    /*--POST Favorite--*/
    async addFavorite({
      state,
      commit
    }, payload) {
      let adDetails
      let path
      if (!state.isFavoritesView) {
        adDetails = state.ads.find(ad => ad.property == payload.adIRI)
        path = _.get(adDetails, 'favorite["@id"]')
      }
      if (state.isFavoritesView) {
        adDetails = state.favorites.find(ad => ad.property == payload.adIRI)
        path = adDetails["@id"]
      }
      /*--PUT--*/
      if ('favorite' in adDetails || adDetails["@type"] == 'Favorite') {
        return Api().put(path, {
          property: payload.adIRI,
          search: state.currentSearch["@id"],
          comments: payload.comment
        }).then(res => {
          let favoriteIRI = res.data["@id"]
          commit('SET_FAVORITE', {
            payload,
            favoriteIRI
          })
        })
      } else {
        /*--POST--*/
        return Api().post('/favorites', {
          property: payload.adIRI,
          search: state.currentSearch["@id"],
          comments: payload.comment
        }).then(res => {
          let favoriteIRI = res.data["@id"]
          commit('push', {
            property: 'favorites',
            newValue: res.data
          })
          commit('SET_FAVORITE', {
            payload,
            favoriteIRI
          })
          Vue.notify({
            group: 'global',
            title: 'Annonce ajoutée aux favoris',
          })
        })
      }
    },

    /*--DELETE Favorite--*/
    async removeFavorite({
      commit
    }, favoriteIRI) {
      return Api().delete(favoriteIRI).then(() => {
        commit('REMOVE_FAVORITE', favoriteIRI)
        Vue.notify({
          group: 'global',
          title: 'Annonce retirée des favoris',
        })
      })
    },
    /*--------SETTINGS--------*/
    /*-- Update or Create Customization--*/
    async updateOrCreateCustomization({
      dispatch,
      getters
    }) {
      if (!getters.doesUserHaveCustomizationInDB) {
        dispatch('createCustomization').then(() => {
          dispatch('fetchUserData')
        })
      } else {
        dispatch('updateCustomization').then(() => {
          dispatch('fetchUserData')
        })
      }
    },
    /*-- Create Customization--*/
    async createCustomization({
      state,
      commit
    }) {
      return Api()
        .post('/customizations', {
          user: state.userData['@id'],
          logo: state.userData.customization.logo['@id'],
          primaryColor: state.userData.customization.primaryColor,
          senderEmailAddress: state.userData.customization.senderEmailAddress,
          senderEmailName: state.userData.customization.senderEmailName,
          displayContactEmail: state.userData.customization.displayContactEmail,
          displayContactName: state.userData.customization.displayContactName,
          displayContactPhone: state.userData.customization.displayContactPhone,
          enabled: state.userData.customization.enabled,
        })
        .then(() => {
          Vue.notify({
            group: 'global',
            title: "⚠️ Veuillez confirmer votre adresse email d'expédition en cliquant sur le lien qui vous a été envoyé",
          })
          commit('SET_HAS_CUSTOMIZATION_BEEN_UPDATED', false)
        })
    },
    /*-- Update Customization--*/
    async updateCustomization({
      state,
      commit
    }, emailPreview) {
      return Api()
        .put(state.userData.customization['@id'], {
          user: state.userData['@id'],
          logo: state.userData.customization.logo['@id'],
          primaryColor: state.userData.customization.primaryColor,
          senderEmailAddress: state.userData.customization.senderEmailAddress,
          senderEmailName: state.userData.customization.senderEmailName,
          displayContactEmail: state.userData.customization.displayContactEmail,
          displayContactName: state.userData.customization.displayContactName,
          displayContactPhone: state.userData.customization.displayContactPhone,
          enabled: state.userData.customization.enabled,
        })
        .then((res) => {
          if (res.data.senderEmailVerified == false)
            Vue.notify({
              group: 'global',
              title: "Veuillez confirmer votre adresse email d'expédition en cliquant sur le lien qui vous a été envoyé",
            })
          if (emailPreview != true)
            Vue.notify({
              group: 'global',
              title: 'Votre personnalisation a bien été mise à jour.',
            })
          if (state.userData.customization.enabled != true) {
            commit('SET_HIDE_PROPERTY_CONTACT', false)
          }
          commit('SET_HAS_CUSTOMIZATION_BEEN_UPDATED', false)
        })
    },
    /*-- GET Customization--*/
    async getCustomization({
      state
    }) {
      return Api()
        .get(state.userData.customization['@id'])
        .then((res) => {
          if (res.data) return res.data
        })
    },
    /*-- Update or Create customization and send Email Preview --*/
    async emailPreview({
      dispatch
    }) {
      dispatch('updateCustomization', true).then(() => {
        dispatch('sendEmailPreview')
      })
    },
    /*--Send Email Preview--*/
    async sendEmailPreview({
      state
    }) {
      return Api()
        .post('/email-preview', {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          Vue.notify({
            group: 'global',
            title: `Un email de test a été envoyé à ${state.userData.email}`,
          })
        })
    },
    /*--Check AWS Verification State--*/
    async checkSenderEmailState() {
      ApiNoBearer().post('/public/check-new-verified-emails', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    /*--Update Password--*/
    async updatePassword({
      commit,
      state
    }) {
      Api()
        .post('/account/change_password', {
          oldPassword: state.oldPassword,
          password: state.password,
        })
        .then(() => {
          commit('SET_PASSWORD', null)
          commit('SET_OLD_PASSWORD', null)
          Vue.notify({
            group: 'global',
            title: 'Votre mot de passe a bien été mis à jour',
          })
        })
    },
    /*--Request New Password--*/
    async forgotPasswordRequest({
      state
    }) {
      ApiNoBearer()
        .post('/public/security/forgot-password/request', {
          email: state.userData.email,
        })
        .then(() => {
          router.push({
            path: '/thanks' + '?email=' + state.userData.email,
          })
        })
    },
    /*--Set New Password--*/
    async forgotPasswordReset({
      state
    }, token) {
      ApiNoBearer()
        .post('/public/security/forgot-password/reset', {
          password: state.password,
          resetToken: token,
        })
        .then(() => {
          router.push({
            name: 'reset-success'
          })
        })
    },

    /*--Billing--*/
    /*--Activate API --*/
    async activateAPIKeys({ commit }) {
      return Api()
        .post("/account/activate_api_usage", {}).then(res => {
          if (res.data.success === true) {
            commit('pushNested', {
              propertyPath: ['jwt', 'user', 'roles'],
              newValue: "ROLE_API"
            })
            commit('updateNested', {
              propertyPath: ['dbSubscriptionObject', 'status'],
              newValue: "active"
            })
            const token = res.data.token
            localStorage.setItem('token', token)
            commit('RETRIEVE_TOKEN', token)
          }
        })
    },
    /*--Fetch API Keys--*/
    async fetchAPIKeys({ commit }) {
      Api()
        .get("/api_keys")
        .then((res) => {
          commit('update', {
            property: 'apiKeys',
            newValue: res.data["hydra:member"]
          })
        })
    },
    /*--Generate API Key--*/
    async postAPIKey({ commit }, keyName) {
      return Api()
        .post("/api_keys", { "name": keyName })
        .then((res) => {
          commit('push', {
            property: 'apiKeys',
            newValue: res.data
          })
          return res.data
        })
    },
    /*--Delete API Key--*/
    async deleteAPIKey({ commit }, id) {
      Api()
        .put(`${id}`, { "isEnabled": false })
        .then((res) => {
          commit('splice', {
            property: 'apiKeys',
            value: id,
            newValue: res.data
          })
        })
    },
    /*--Fetch API Keys Usage--*/
    async fetchAPIKeysUsage({ }, { startDate, endDate }) {
      return Api()
        .get(`account/query_logs_usage?startDate=${startDate}&endDate=${endDate}`)
        .then((res) => {
          return res
        })
    },
    /*--Fetch Webhooks Usage--*/
    async fetchWebhooksUsage({ }, { startDate, endDate }) {
      return Api()
        .get(`account/webhooks_usage?startDate=${startDate}&endDate=${endDate}`)
        .then((res) => {
          return res
        })
    },
    /*--Fetch all Bills--*/
    async fetchBills({
      commit
    }) {
      Api()
        .get('/account/invoices')
        .then((res) => {
          commit('SET_BILLS', res.data)
        })
    },
    /*--Logout--*/
    logout({
      commit
    }) {
      router.push({
        name: 'login'
      }).catch(() => { })
      this.reset()
      localStorage.removeItem('token')
      commit('RETRIEVE_TOKEN', null)
    },
    /*--------MACRO ANALYTICS--------*/
    async getDataFromES({
      commit,
    }, payload) {
      return fetch('https://flow.notif.immo/webhook/category-distribution', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          transactionType: payload.transactionType
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) commit('SET_DATA', {
            target: payload.target,
            value: data.aggregations.publishers.sitePublisher.buckets
          })
          commit('UPDATE_DATA_LOADING', false)
        })

    },
    async getData({
      commit,
      state
    }, payload) {
      commit('UPDATE_DATA_LOADING', true)
      return fetch(
        'https://flow.notif.immo/webhook/data', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          questionId: payload.questionId,
          location: state.data.city,
          transactionType: payload.transactionType ? payload.transactionType : state
            .data.transactionType,
          publisherType: payload.publisherType != null ? payload.publisherType : 1
        }),
      },
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) commit('SET_DATA', {
            target: payload.target,
            value: data
          })
          commit('UPDATE_DATA_LOADING', false)
        })
    },
    async getWorkflow({
      commit,
      state
    }, payload) {
      commit('UPDATE_LOADING', true)
      return fetch(
        `https://flow.notif.immo/webhook/${payload.target}`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          location: state.data.city,
        }),
      },
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) commit('SET_DATA', {
            target: payload.target,
            value: data
          })
          commit('UPDATE_LOADING', false)
        })
    },
  },
  getters: {
    /***** GENERAL *****/
    isViewActive: (state) => (view) => {
      return state.currentView === view
    },
    isNavOpen: (state) => {
      return state.isNavOpen
    },
    isFilterOpen: (state) => {
      return state.isFilterOpen
    },
    isLoggedIn: (state) => {
      return state.token != null
    },
    isEmailValid: (state) => (email) => {
      let regexEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regexEmail.test(email)
    },
    /***** CUSTOMIZATION *****/
    doesUserHaveCustomization: (state) => {
      return state.userData.customization != null
    },
    doesUserHaveCustomizationInDB: (state) => {
      return _.has(state, 'userData.customization.@id')
    },
    isLogoSet: (state) => {
      return (
        _.has(state, 'userData.customization.logo.contentUrl') &&
        !_.isNil(state.userData.customization.logo.contentUrl)
      )
    },
    isSenderEmailAddressVerified: (state) => {
      return Boolean(_.get(state, 'userData.customization.senderEmailVerified'))
    },
    isSenderEmailNameSet: (state) => {
      return Boolean(
        _.has(state, 'userData.customization.senderEmailName') &&
        _.size(state.userData.customization.senderEmailName) > 1,
      )
    },
    isSenderInformationSet: (state, getters) => {
      return Boolean(
        _.has(state, 'userData.customization.senderEmailAddress') &&
        getters.isEmailValid(
          state.userData.customization.senderEmailAddress,
        ) &&
        getters.isSenderEmailNameSet,
      )
    },
    isDisplayContactNameSet: (state) => {
      return Boolean(
        _.has(state, 'userData.customization.displayContactName') &&
        _.size(state.userData.customization.displayContactName) > 1,
      )
    },
    isDisplayContactInformationSet: (state, getters) => {
      return Boolean(
        _.has(state, 'userData.customization.displayContactEmail') &&
        getters.isEmailValid(
          state.userData.customization.displayContactEmail,
        ) &&
        getters.isDisplayContactNameSet &&
        state.isPhoneValid,
      )
    },
    isCustomizationEnabled: (state) => {
      return Boolean(
        _.has(state, 'userData.customization.enabled') &&
        state.userData.customization.enabled == true,
      )
    },
    isHidePropertyContactEnabled: (state) => {
      return Boolean(state.currentSearch.hidePropertyContact)
    },
    /***** SUBSCRIPTION *****/
    isSubscriptionValid: (state) => {
      return state.dbSubscriptionObject.isValid == true
    },
    hasSkippedInitialCheckout: (state) => {
      return state.dbSubscriptionObject.hasSkippedInitialCheckout
    },
    hasPaymentMethod: (state) => {
      return state.dbSubscriptionObject.hasPaymentMethod
    },
    isStripeFinalized: (state) => {
      return state.dbSubscriptionObject.isStripeFinalized
    },
    isSubscriptionTrialing: (state) => {
      return state.dbSubscriptionObject.status == 'trialing'
    },
    isSubscriptionActive: (state) => {
      return state.dbSubscriptionObject.status == 'active'
    },
    isDbSubscriptionLoaded: (state) => {
      return Object.keys(state.dbSubscriptionObject).length > 0
    },
    getSubscriptionPlan: (state) => {
      return state.dbSubscriptionObject.plan
    },
    isEnterprisePlan: (state) => {
      return (
        state.dbSubscriptionObject.plan == 'melo_enterprise_multi_search_plan'
      )
    },
    isPROPlan: (state) => {
      return state.dbSubscriptionObject.plan == 'melo_multi_search_plan'
    },
    isStandardPlan: (state) => {
      return (
        state.dbSubscriptionObject.plan == 'melo_standard_multi_search_plan'
      )
    },
    isPRO2019Plan: (state) => {
      return state.dbSubscriptionObject.plan == 'melo_multi_search_plan_2019'
    },
    isUnsubscribedPlan: (state) => {
      return state.dbSubscriptionObject.plan == 'melo_unsubscribed_plan'
    },
    formatPlanName: (state) => {
      if (
        state.dbSubscriptionObject.plan == 'melo_multi_search_plan' ||
        state.dbSubscriptionObject.plan == 'melo_multi_search_plan_2019'
      )
        return 'PRO'
      if (state.dbSubscriptionObject.plan == 'melo_standard_multi_search_plan')
        return 'Standard'
      if (state.dbSubscriptionObject.plan == 'melo_enterprise_multi_search_plan')
        return 'Entreprise'
    },

    /***** STRIPE CARD *****/
    isStripeUserObjectLoaded: (state) => {
      return Object.keys(state.stripeUserObject).length > 0
    },
    getLast4: (state) => {
      return state.stripeUserObject.card.last4
    },
    doesCardExist: (state) => {
      return state.stripeUserObject.card != null
    },
    isCardMastercard: (state) => {
      return state.stripeUserObject.card.brand == 'mastercard'
    },
    isCardVisa: (state) => {
      return state.stripeUserObject.card.brand == 'visa'
    },
    getExpirationDate: (state) => {
      return (
        state.stripeUserObject.card.exp_month +
        ' / ' +
        state.stripeUserObject.card.exp_year
      )
    },
    /***** ADS *****/
    getProperCreatedAt: (state) => {
      let createdAts = state.currentAd.adverts.map(el => el.createdAt).flat()
      let sortedAts = createdAts.sort((a, b) => a.date - b.date)
      return sortedAts[0]
    },
    cleansedEvents: (state, getters) => {
      let events = state.currentAd.adverts.map(advert => advert.events).flat()
      let uniqueEvents = [...new Map(events.map(event => [event['fieldNewValue'] + event[
        'fieldName'], event])).values()]
      let intEvents = uniqueEvents.filter(el => el.percentVariation)

      if (intEvents && intEvents.length) {
        let timeline = intEvents.map(obj => {
          let tmp = {}
          if (obj.fieldName == 'surface') {
            tmp.icon = require('../assets/icons/area-rounded.svg')
            tmp.description = obj.fieldOldValue + ' m² → ' + obj.fieldNewValue + ' m²'
          }
          if (obj.fieldName == 'price') {
            tmp.icon = require('../assets/icons/price-rounded.svg')
            tmp.description = getters.thousandSeparator(obj.fieldOldValue) + ' € → ' +
              getters.thousandSeparator(obj.fieldNewValue) + ' €'
          }

          let properties = {
            icon: tmp.icon,
            title: obj.percentVariation > 0 ? '+' + obj.percentVariation + ' %' : obj
              .percentVariation + ' %',
            description: tmp.description,
            date: new Date(obj.createdAt),
          };
          return properties;
        });
        const sortedTimeline = timeline.sort((a, b) => b.date - a.date)
        return sortedTimeline
      }
    },
    cleansedContacts: (state) => {
      const contacts = state.currentAd.adverts.map(advert => ({
        ...advert.contact,
        updatedAt: advert.updatedAt,
        publisherType: advert.publisher.type
      }));
      const uniqueContacts = []

      function splitWords(str) {
        return str.toLowerCase().replace(/[^a-zA-Z0-9\s]/g, "").split(/\s+/);
      }

      function isAgencySimilar(agency1, agency2) {
        const words1 = new Set(splitWords(agency1));
        const words2 = new Set(splitWords(agency2));

        const commonWords = new Set([...words1].filter(word => words2.has(word)));
        const totalWords = new Set([...words1, ...words2]);

        // Calculate similarity (e.g., proportion of common words)
        const similarity = commonWords.size / totalWords.size;
        return similarity >= 0.1; // You can adjust this threshold as needed
      }

      function countNonNullProperties(contact) {
        return Object.values(contact).filter(value => value !== null && value !== undefined).length;
      }

      contacts.forEach(contact => {
        if (!contact.agency) return; // Skip if agency is null or undefined

        const existingIndex = uniqueContacts.findIndex(uniqueContact =>
          uniqueContact.agency && isAgencySimilar(uniqueContact.agency, contact.agency)
        );

        if (existingIndex !== -1) {
          // Compare the richness of the contact object
          if (countNonNullProperties(contact) > countNonNullProperties(uniqueContacts[existingIndex])) {
            uniqueContacts[existingIndex] = contact;
          }
        } else {
          uniqueContacts.push(contact);
        }
      });


      let noContact = contacts.filter(contact => contact.email || contact.phone || contact
        .agency || contact.name)

      let uniquesNoContact = [...new Map(noContact.map(contact => [contact['name'] + contact[
        'agency'], contact])).values()]
      if (uniqueContacts && uniqueContacts.length) {
        return uniqueContacts
      } else {
        return uniquesNoContact
      }
    },
    getUrl: (state) => {
      // get adverts with urls that are not null
      let disclosedAdverts = state.currentAd.adverts.filter(advert => advert.url)
      // get adverts that are not expired
      let activeAdverts = disclosedAdverts.filter(advert => advert.expired === false)
      // if there are some => get the most recent crawled advert
      if (activeAdverts && activeAdverts.length) {
        return activeAdverts.sort((a, b) => new Date(b.lastCrawledAt).getTime() - new Date(a
          .lastCrawledAt).getTime())[0].url;
      } else {
        // if not get the most recent crawled advert overall
        return disclosedAdverts && disclosedAdverts.length ? disclosedAdverts.sort((a, b) =>
          new Date(b.lastCrawledAt).getTime() - new Date(a.lastCrawledAt).getTime())[0]
          .url : null
      }
    },
    /***** SEARCH *****/
    thousandSeparator: (state) => (amount) => {
      if (
        amount !== "" ||
        amount !== undefined ||
        amount !== 0 ||
        amount !== "0" ||
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return amount;
      }

    },
    doesUserHaveAnySearch: (state) => {
      return Boolean(state.searches.length > 0)
    },
    countAdResults: (state) => {
      return state.ads.length
    },
    isAdInCart: (state) => (adIRI) => {
      return state.adsInCart.includes(adIRI)
    },
    getAdsInCart: (state) => {
      let ads = state.ads.filter(ad => {
        return state.adsInCart.includes(ad.property);
      })
      return ads
    },
    countAdsInCart: (state) => {
      return state.adsInCart.length
    },
    countFavorites: (state) => {
      if (!_.isEmpty(state.favorites)) return state.favorites.length
    },
    cartIsNotEmpty: (state) => {
      return state.adsInCart.length > 0
    },
    getSearchesTitles: (state) => {
      return state.searches.map((search) => search.title)
    },
    getAndExpressions: (state) => {
      return state.currentSearch.expressions.map((search) => search.title)
    },
    doesSearchHaveUUID: (state) => {
      return state.currentSearch.hasOwnProperty(['@id'])
    },
    getCurrentSearchId: (state) => {
      return state.currentSearch['@id']
    },
    getCurrentSearchTitle: (state) => {
      return state.currentSearch.title
    },
    getCurrentSortBy: (state) => {
      return state.sortBy
    },
    getCurrentFromTo: (state) => {
      return state.fromTo
    },
    formatDate: (state) => (date) => {
      return Vue.moment(date).fromNow()
    },
    getCurrentPeriodEnd: (state) => {
      return Vue.moment
        .unix(state.stripeUserObject.subscription.current_period_end)
        .calendar()
    },
    getDaysAgo: (state) => (hours) => {
      return Vue.moment().subtract(hours, 'hours').format('YYYY-MM-DD')
    },
    getThirtyDaysAgo() {
      return Vue.moment().subtract(720, 'hours').format('YYYY-MM-DD')
    },
    getSevenDaysAgo() {
      return Vue.moment().subtract(168, 'hours').format('YYYY-MM-DD')
    },
    getFortyEightHoursAgo() {
      return Vue.moment().subtract(48, 'hours').format('YYYY-MM-DD')
    },
    getTwentyFourHoursAgo() {
      return Vue.moment().subtract(24, 'hours').format('YYYY-MM-DD')
    },
    getNow() {
      return Vue.moment().format('YYYY-MM-DD HH:mm:ss')
    },
    getNextPage: (state) => {
      let params = new URLSearchParams(state.nextPage)
      return params.get('page')
    },
    isLastPage: (state) => {
      return state.currentPage == state.lastPage
    },
    doesNextPageExist: (state) => {
      return state.nextPage != null
    },
    isLastPageFavorite: (state) => {
      return state.favoriteCurrentPage == state.favoriteLastPage
    },
    doesNextPageFavoriteExist: (state) => {
      return state.favoriteNextPage != null
    },
    removeUnrequiredParamsFromFetchAds: (state, getters) => {
      const currentSearch = state.currentSearch
      const {
        '@id': id,
        '@type': type,
        '@context': context,
        title,
        user,
        createdAt,
        updatedAt,
        notificationRecipient,
        notificationEnabled,
        lastAlertAt,
        includedCities,
        includedDepartments,
        excludedCities,
        includedSiteCategories,
        excludedSiteCategories,
        ...cleansedCurrentSearch
      } = currentSearch
      cleansedCurrentSearch.expired = state.expired === true ? null : false
      cleansedCurrentSearch.includedCities = getters.getIRISFromIncludedCities
      cleansedCurrentSearch.includedDepartments =
        getters.getIRISFromIncludedDepartments
      cleansedCurrentSearch.excludedCities = getters.getIRISFromExcludedCities
      cleansedCurrentSearch.includedSiteCategories = getters.getIRISFromIncludedSiteCategories
      cleansedCurrentSearch.excludedSiteCategories = getters.getIRISFromExcludedSiteCategories
      if (_.has(state, "currentSearch['@id']")) cleansedCurrentSearch.search = state
        .currentSearch['@id'].split('/')[2]

      return cleansedCurrentSearch
    },
    getIRISFromIncludedCities: (state) => {
      if (_.has(state, 'currentSearch.includedCities'))
        return state.currentSearch.includedCities.map((i) => i['@id'])
    },
    getIRISFromIncludedDepartments: (state) => {
      if (_.has(state, 'currentSearch.includedDepartments'))
        return state.currentSearch.includedDepartments.map((i) => i['@id'])
    },
    getIRISFromExcludedCities: (state) => {
      if (_.has(state, 'currentSearch.excludedCities'))
        return state.currentSearch.excludedCities.map((i) => i['@id'])
    },
    getIRISFromIncludedSiteCategories: (state) => {
      if (_.has(state, 'currentSearch.includedSiteCategories'))
        return state.currentSearch.includedSiteCategories.map((i) => i['@id'])
    },
    getIRISFromExcludedSiteCategories: (state) => {
      if (_.has(state, 'currentSearch.excludedSiteCategories'))
        return state.currentSearch.excludedSiteCategories.map((i) => i['@id'])
    },
    currentSearchCleansedForPUT: (state, getters) => {
      const currentSearch = state.currentSearch
      const {
        includedCities,
        includedDepartments,
        excludedCities,
        ...cleansedCurrentSearch
      } = currentSearch
      cleansedCurrentSearch.includedCities = getters.getIRISFromIncludedCities
      cleansedCurrentSearch.includedDepartments =
        getters.getIRISFromIncludedDepartments
      cleansedCurrentSearch.excludedCities = getters.getIRISFromExcludedCities
      cleansedCurrentSearch.includedSiteCategories = getters.getIRISFromIncludedSiteCategories
      cleansedCurrentSearch.excludedSiteCategories = getters.getIRISFromExcludedSiteCategories
      cleansedCurrentSearch.initiatedFromDashboard = true
      return cleansedCurrentSearch
    },
    getLastUpdatedSearch: (state) => {
      let searchesSorted = _.orderBy(
        state.searches,
        function (s) {
          return new Vue.moment(s.updatedAt).format('YYYYMMDDh:mm:ss')
        },
        ['desc'],
      )

      return searchesSorted.length ? searchesSorted[0] : null
    },
    /***** DATA *****/
    getDataLabelsFromES: (state) => (filter) => {
      let labels = []
      state.data[filter].map((obj) => {
        labels.push(obj.key)
      })
      if (state.data[filter] != null) return labels
    },
    getDataDistributionFromES: (state) => (filter) => {
      let distribution = []
      let total = 0
      for (const obj of state.data[filter]) {
        total = total + obj.doc_count
      }
      state.data[filter].map((obj) => {
        distribution.push(((obj.doc_count / total) * 100).toFixed(2))
      })
      if (state.data[filter] != null) return distribution
    },
    getDataLabels: (state) => (filter) => {
      let labels = []
      state.data[filter].map((obj) => {
        labels.push(obj[0])
      })
      if (labels.includes('Paris 16e Nord')) {
        const index = labels.indexOf('Paris 16e Nord');
        labels.splice(index, 1)
      }
      if (labels.includes('Paris 16e Sud')) {
        const index = labels.indexOf('Paris 16e Sud');
        labels[index] = "Paris 16e"
      }
      if (state.data[filter] != null) return labels
    },
    getDataAvgPricesPerMeter: (state) => (filter) => {
      let avgPricesPerMeter = []
      state.data[filter].map((obj) => {
        avgPricesPerMeter.push(obj[2].toFixed(2))
      })
      if (state.data.city.includes('75116') && filter != 'dvf') {
        let avg16 = (parseFloat(avgPricesPerMeter[15]) + parseFloat(avgPricesPerMeter[20])) /
          2
        avgPricesPerMeter[15] = avg16.toFixed(2)
        avgPricesPerMeter.splice(20, 1)
      }
      if (state.data[filter] != null) return avgPricesPerMeter

    },
    getDataDistribution: (state) => (filter) => {

      let distribution = []
      state.data[filter].map((obj) => {
        let percentage = parseFloat(obj[1].toFixed(4) * 100)
        distribution.push(percentage.toFixed(2))
      })
      if (state.data[filter] != null) return distribution
    },
    getDataDistributionCount: (state) => (filter) => {
      let distribution = []
      state.data[filter].map((obj) => {
        distribution.push(obj[1])
      })
      if (state.data[filter] != null) return distribution
    },
    getAdsGradient: (state, getters) => (filter) => {
      const colors = gradient(
        ['#439cfb', '#E953DF'],
        getters.getDataLabels(filter).length,
      )
      return colors
    },
    getSearchesGradient: (state, getters) => (filter) => {
      const colors = gradient(
        ['#E9537B', '#ffeb3b'],
        getters.getDataLabels(filter).length,
      )
      return colors
    },
  },
})

export default store
